import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Button from "./button"

const ProjectCard = React.forwardRef(({ project, className }, ref) => {
  return (
    <div
      ref={ref}
      className={
        "h-96 flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden " +
        className
      }
    >
      <div className="basis-7/12 relative">
        <GatsbyImage
          image={project.image}
          alt={project.image.description || ""}
          layout="fullWidth"
          objectPosition="center"
          objectFit="cover"
          className="!absolute !inset-0"
          placeholder="blurred"
        />
      </div>
      <div className="grow flex flex-col p-4">
        <section
          className="grow prose prose-stone prose-headings:font-heading prose-p:font-body prose-a:text-primary hover:prose-a:text-primary-highlighted px-4 py-4"
          dangerouslySetInnerHTML={{ __html: project.summary }}
        />
        <Link to={project.url}>
          <Button className="w-full">Project Details</Button>
        </Link>
      </div>
    </div>
  )
})

export default ProjectCard
