import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import * as React from "react"
import H2 from "../components/h2"
import Layout from "../components/layout"
import ProjectCard from "../components/project-card"
import Seo from "../components/seo"

export default function ProjectsPage() {
  const data = useStaticQuery(graphql`
    query ProjectsQuery {
      allContentfulProject {
        nodes {
          title
          url
          heroImage {
            gatsbyImageData
          }
          summary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  console.debug("Projects Data: ", data)

  const projects = data.allContentfulProject.nodes.map(node => {
    return {
      summary: node.summary.childMarkdownRemark.html,
      image: getImage(node.heroImage),
      url: `/projects/${node.url}`,
    }
  })

  return (
    <Layout>
      <Seo title="Projects" />
      <div className="py-8 px-8 lg:px-16">
        <H2>Projects</H2>
        <div className="py-8 grid grid-cols-1 w-full lg:!w-3/4 mx-auto gap-y-8 gap-x-8">
          {projects.map((project, index) => (
            <ProjectCard project={project} key={index} />
          ))}
        </div>
      </div>
    </Layout>
  )
}
